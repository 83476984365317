<template>
  <v-row class="font-family-raleway-medium">
    <v-col class="create-account-col" cols="12" md="6" offset-md="3">
      <v-card class="new-account-card text-center" :loading="loading">
        <h2 class="pt-8">{{ $t("create_account_page.new_account") }}</h2>
        <p class="mt-5">
          {{ $t("create_account_page.description") }}
        </p>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            class="input-border font-family-raleway-medium"
            v-model="accountName"
            :label="$t('create_account_page.account_name_input')"
            :rules="nameRules"
            height="60"
            outlined
          />
          <v-autocomplete
            class="font-family-raleway-medium input-border"
            @change="changeCountry"
            :label="$t('create_account_page.search_country')"
            item-text="country"
            :rules="nameRules"
            v-model="country"
            :items="countrys"
            height="60"
            outlined
          ></v-autocomplete>
          <v-text-field
            v-model="timeZoneDisplayName"
            class="input-border"
            :rules="nameRules"
            :label="$t('create_account_page.timezone')"
            height="60"
            readonly
            outlined
          />
          <v-select
            @input="handleChingeCurrenciess"
            class="input-border"
            v-model="currency"
            :items="currencys"
            :rules="nameRules"
            item-value="name"
            item-text="name"
            :label="$t('create_account_page.currency')"
            height="60"
            outlined
          />
          <v-row class="align-center">
            <v-col class="mt-5 font-family-raleway-medium" cols="12" md="5">
              <v-btn class="back-btn text-decoration-underline" to="/welcome">
                {{ $t("back_button") }}
              </v-btn>
            </v-col>
            <v-col
              class="mt-5 font-family-raleway-medium text-right"
              cols="12"
              md="7"
            >
              <v-btn
                class="button font-weight-bold font-family-raleway-medium"
                height="60"
                @click="createStore"
                width="200"
                :loading="loading"
              >
                {{ $t("create_campaign_page.next_button") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { accountService } from "../../services/account";
import countriesData from "@/assets/json-data/countries.json";
import { baseUrl } from "@/main";

export default {
  name: "CreateAccount",
  props: {
    userId: Number,
  },
  data: () => ({
    loading: false,
    valid: true,
    accountName: "",
    country: "",
    countrys: countriesData,
    timeZoneDisplayName: "",
    timeZones: [],
    countryValue: "",
    currency: "",
    currencys: [],
  }),
  async created() {
    try {
      const res2 = await accountService.getCurrencies();
      this.currencys = res2.data.map((item) => {
        return {
          ...item,
          disabled: item.name !== "USD",
          fontWeight: "Raleway medium",
        };
      });
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    nameRules: [
      function (v) {
        return !!v || this.$t("required_text");
      },
    ],
    countryRules: [
      function (v) {
        return !!v || this.$t("required_text");
      },
    ],
  },
  methods: {
    handleChingeCurrenciess(value) {
      this.currency = value;
    },
    changeCountry(val) {
      const { timeZoneDatas } = countriesData.find(
        (item) => item.country === val
      );
      this.timeZone = timeZoneDatas[0]?.timeZone;
      this.offsetGMT = timeZoneDatas[0]?.offsetGMT;
      this.timeZoneDisplayName = timeZoneDatas[0]?.displayName;
    },
    async createStore() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await this.$refs.form.validate();
      if (this.valid) {
        const userId = localStorage.getItem("userId");
        const data = {
          userId: userId,
          accountName: this.accountName,
          country: this.country,
          timeZone: this.timeZone,
          currency: this.currency,
          offsetGMT: this.offsetGMT,
        };
        try {
          await axios.post(baseUrl + "/accounts", data);
          if (userId) {
            const resAccount = await accountService.getByUserId(userId);
            if (resAccount?.data?.length > 0) {
              const accId = resAccount?.data[0].id;
              localStorage.setItem("accountId", accId);
            }
          }
          this.$notify({
            group: "foo",
            title: "Account was successfully created!",
            type: "success",
          });
          window.location.replace("/my-campaigns");
        } catch (e) {
          const errText = e.response?.data?.title;
          this.$notify({
            group: "foo",
            title: errText,
            type: "error",
          });
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100% !important;
}
.create-account-col {
  padding: 0 80px !important;
  margin-top: 80px;
  @media screen and (max-width: 550px) {
    padding: 0 20px !important;
  }

  .new-account-card {
    box-shadow: none !important;
    border-radius: 20px;
    padding: 0 50px 40px 50px !important;

    @media screen and (max-width: 600px) {
      padding: 15px !important;
    }

    h4 {
      font-size: 32px;
    }
  }

  .create-account-btn {
    border-radius: 16px !important;
    background-color: #0057ff !important;
    color: #fff !important;
    font-weight: bold;
    box-shadow: 0 4px 24px rgba(34, 41, 51, 0.1) !important;
    @media screen and (max-width: 600px) {
      height: 48px !important;
    }
  }

  .router-link {
    color: #000 !important;
  }
}
</style>
